exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-505-jsx": () => import("./../../../src/pages/505.jsx" /* webpackChunkName: "component---src-pages-505-jsx" */),
  "component---src-pages-contactanos-gracias-tsx": () => import("./../../../src/pages/contactanos-gracias.tsx" /* webpackChunkName: "component---src-pages-contactanos-gracias-tsx" */),
  "component---src-pages-contactanos-tsx": () => import("./../../../src/pages/contactanos.tsx" /* webpackChunkName: "component---src-pages-contactanos-tsx" */),
  "component---src-pages-eventos-gracias-tsx": () => import("./../../../src/pages/eventos-gracias.tsx" /* webpackChunkName: "component---src-pages-eventos-gracias-tsx" */),
  "component---src-pages-eventos-tsx": () => import("./../../../src/pages/eventos.tsx" /* webpackChunkName: "component---src-pages-eventos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-trabaja-con-nosotros-tsx": () => import("./../../../src/pages/trabaja-con-nosotros.tsx" /* webpackChunkName: "component---src-pages-trabaja-con-nosotros-tsx" */)
}

